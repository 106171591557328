<template>
  <QingHeader></QingHeader>
  <router-view></router-view>
  <QingFooter></QingFooter>
</template>

<script>
import QingHeader from '@/components/QingHeader';
import QingFooter from "@/components/QingFooter";

export default {
  name: 'App',
  components: {
    QingHeader,
    QingFooter
  }
}
</script>

<style>
:root {
  font-size: 14px;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
}

body, html {
  /* 平滑滚动 */
  scroll-behavior: smooth;
  height: auto;
  color: #fff;

  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a {
  text-decoration: none;
  outline: none;
  color: #373838;
}

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#app {

}

/* 过度动画 */
.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>
