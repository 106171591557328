import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
    history: createWebHistory(), // 路由模式
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('@/pages/IndexMain')
        },
        {
            path: '/new',
            redirect: '/',
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@/pages/IndexMain')
        },
        {
            path: '/team',
            name: 'team',
            component: () => import('@/pages/team/MyTeam')
        },
        {
            path: '/job',
            name: 'job',
            component: () => import('@/pages/job/AddJob')
        },
        {
            path: '/we',
            name: 'we',
            component: () => import('@/pages/we/RelationWe')
        },
        {
            path: '/business',
            name: 'business',
            component: () => import('@/pages/business/BusinessList')
        },
        {
            path: '/news',
            name: 'news',
            component: () => import('@/pages/news/NewsCentre'),
            children: []
        },
        {
            path: '/news/article/:id',
            component: () => import('@/pages/news/NewsInfo')
        }
    ]
})

export default router